@view-transition {
  navigation: auto;
}

@keyframes animate-out {
  from {
    opacity: 1;
  }
  80%, to {
    opacity: 0;
  }
}

@keyframes animate-in {
  from, 30% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  ::view-transition-old(root) {
    animation: var(--page-transition-duration) ease-in both animate-out;
  }
  ::view-transition-new(root) {
    animation: var(--page-transition-duration) ease-in both animate-in;
  }
}

:root {
  interpolate-size: allow-keywords;

  --page-transition-duration: 0.5s;

  /* Define color palette */
  --t-c-white: #fffefc;

  --t-c-black: #0c2d48;

  --t-c-accent-light: #b1d4e0;
  --t-c-accent-dark: #2e8bc0;
  --t-c-accent-med: #145da0;

  /* Other global variables */
  --section-gap: 160px;
  --p-width: 40em;
  --max-vw-width: min(90vw, 95%);
}

/* semantic color variables for this project */
:root {
  --color-body:  var(--t-c-white);
  --color-background: var(--color-body);

  --color-border: var(--t-c-accent-light);

  --color-heading: var(--t-c-accent-med);
  --color-text: var(--t-c-black);
  --color-link: var(--t-c-accent-dark);

  --color-hover: var(--t-c-accent-light);
}

@custom-media --dark-mode (prefers-color-scheme: dark);
@media (--dark-mode) {
  :root {
    --color-body: var(--t-c-black);

    --color-border: var(--t-c-accent-med);

    --color-heading: var(--t-c-accent-med);
    --color-text: var(--t-c-white);
  }
}

@custom-media --use-side-nav (width >= 700px);
@custom-media --no-side-nav (width < 700px);

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  color: var(--color-text);
  background: var(--color-background);
  transition: color 0.5s, background-color 0.5s;
  line-height: 1.6;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.2rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-heading);
  max-width: var(--max-vw-width);
}

h2 {
  margin-top: 1em;
  margin-bottom: .2em;
  border-bottom: 1px solid var(--color-border);
}

p {
  margin-top: .9em;
  max-width: min(var(--p-width), var(--max-vw-width));
}

ul {
  max-width: var(--max-vw-width);
}

#app {
  font-weight: normal;
}


.logo {
  display: block;
  margin: 0 auto 2rem;
}

a {
  text-decoration: none;
  color: var(--color-link);
  transition: background-position ease-in .1s;
  background-image: linear-gradient(to left, var(--color-background) 50%, var(--color-hover) 50%);

  background-size: 210% 100%;
  background-position: top right;

  &:enter {
    background-position: top left;
    color: var(--color-body);
  }

}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
}

iframe {
  border: none;
}

#top {
  min-height: 100vh;

  @media (--use-side-nav) {
    height: 100vh;
    display: grid;

    grid-template-areas: "head" "content";
    grid-template-rows: 1.5lh 1fr;
  }
}

a#skip-nav {
  position: absolute;
  z-index: 1000;
  left: 1rem;
  padding: .25rem 1.5rem;
  color: var(--color-text);
  background: var(--color-body);
  border: 1px var(--color-heading);
  border-radius: 0 0 .5rem .5rem;
  transform: translateY(-120%);
  transition: transform .3s;

  &:focus {
    transform: translateY(0);
  }

  body:not(:has(#content)) & {
    display: none;
  }
}

main {
  margin-inline: clamp(.5em, 3%, 3em);

  @media (--no-side-nav) {
    padding-top: 1lh;
  }
}

#content {
  container-type: inline-size;
}

header {
  position: fixed;
  top: 0;
  line-height: 1.5;
  background-color: var(--color-border);
  --color-background: var(--color-border);
  --color-hover: var(--color-link);
  --r: 0;

  @media (--use-side-nav) {
    --r: 13px;
  }

  ul {
    display: flex;
    max-width: 100vw;
    height: 100%;
  }

  background-color: var(--color-background);

  .menu-title {
    @media(--use-side-nav) {
      padding-inline: .5em;
      display: none;
    }
  }

  @media(--no-side-nav) {
    position: fixed;
    top: 2px;
    left: 2px;
    z-index: 5;

    ul#nav {
      display: none;
      border-top: 1px solid var(--color-border);
    }
  }

  &:focus-within ul#nav {
    display: block;
  }

  a {
    color: var(--color-text);
    font-weight: bold;
    height: 100%;
    border-radius: var(--r) var(--r) 0 0;

    &:focus {
      outline: none;
    }
  }

  [aria-current="page"] {
    --color-background: var(--color-body);
  }

  li:not(:has([aria-current="page"])) + li:not(:has([aria-current="page"])) {
    border-left: 1px solid var(--color-link);
  }
}

@media (--use-side-nav) {
  :root {
    --header-width: clamp(6em, 20vw, 15em);
  }

  #top {
    grid-template: "head content" / var(--header-width) 1fr;
  }

  header {
    container-type: inline-size;
    grid-area: head;
    height: 100vh;
    width: var(--header-width);
    border-left: none;
    border-bottom: none;
    padding-top: 2em;
    display: grid;
    align-content: start;
    justify-content: right;

    ul {
      flex-direction: column;
      height: auto;
    }

    ul#nav a {
      height: auto;
      --r: 50vw;
      border-radius: var(--r) 0 0 var(--r);
      padding-left: .5em;

      @container (min-width: 10em) {
        padding-left: clamp(1em, 5vw, 5em);
      }
    }

    ul#nav li + li {
      border-left: none;
    }

    &::before {
      --size: 5em;
      display: block;
      content: "";
      height: var(--size);
      width: var(--size);
      margin: 1em auto;
      border: 2px solid var(--t-c-accent-med);
      border-radius: 15px;
      background: url("/apple-touch-icon.png") local no-repeat;
      background-size: contain;
    }
  }

  #content {
    grid-area: content;
    margin: 0;
    padding: 2rem;
  }
}

.home-page {
  @container (min-width: 80em) {
    display: grid;
    grid-template: minmax(30em, var(--p-width)) minmax(30px, 600px);
    justify-content: start;
    gap: 5em;
  }

  .text {
    grid-row: 1;
  }

  .self-photo {
    grid-column: 2;
    max-width: 100%;
  }
}

.full-page {
  &#content {
    height: 100%;
    @media (--no-side-nav) {
      height: 100vh;
    }
    margin: 0;
    padding: 0;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

a[aria-current='page'] {
  color: inherit;
}

ul#nav {
  padding: 0;

  li {
    list-style: none;
  }

  a {
    display: inline-block;
    width: 100%;
    padding: .4em .5em;
    border-left: 1px solid var(--color-border);
  }

  a:first-of-type {
    border: 0;
  }
}

.tagList {
  padding: 0;

  .tag {
    display: inline;
    margin-inline: .2em;
    border: 3px solid var(--color-border);
    border-radius: 8px;

    --color-background: var(--color-border);
    --color-hover: var(--color-link);

    a {
      padding-inline: .3em;
      color: var(--color-text);
    }

    &:enter {
      border-color: var(--color-link)
    }
  }
}

.pageList {
  margin-top: 1em;
  border-top: 3px solid var(--color-border);

  h2, ul, li {
    display: inline;
  }

  li::before {
    content: " • ";
  }

  li:first-of-type::before {
    content: "";
  }
}

.post {
  .meta {
    margin-top: 1.5em;

    .tagList,
    time {
      font-size: .8em;
    }
    .p-author {
      display: none;
    }
  }
}

.adjacentPages {
  max-width: 100vw;

  ul {
    padding-left: 0;
    gap: 3em;

    @container (min-width: 12em) {
      display: grid;
      grid-template-areas: "prev next";
      grid-template-columns: 1fr 1fr;

      li {
        display: inline;
      }
    }

  }

  li {
    display: block;
    position: relative;
  }

  a {
    text-wrap: balance;
  }

  --arrow-padding: .2em;

  .previous {
    grid-area: prev;
    .arrow {
      padding-right: var(--arrow-padding);
    }
  }

  .next {
    grid-area: next;
    justify-self: end;
    text-align: right;
    .arrow { padding-left: var(--arrow-padding); }
  }
}

pre[class*="language-"] {
  max-width: min(95%, 90vw, 45em);
  max-height: 40em;
  overflow: scroll;
}

.named-fence-block {
  position: relative;
  padding-top: 2em;

  .named-fence-filename {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 .5em;
    width: 100%;
    background: #666;

    &::before {
      content: "📄 "
    }
  }
}

fieldset {
  border: 1px solid var(--color-border);
  width: fit-content;
}

.bug-list {
  padding-block: 1em;
  display: flex;
  flex-direction: column;

  #bug-filters {
    display: none;

    /* Only show when :has() is supported */
    &:has(input) { display: block; }

    input {
      margin-right: .5em;
    }

    label {
      display: block;
    }
  }

  &:has(input#patched:checked) .bug-entry:not([data-patch]) {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    border: none;
    display: none;
  }

  &:has(input#notable:checked) .bug-entry:not([data-notable]) {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    border: none;
    display: none;
  }

  .bug-entry {
    width: 90%;
    height: auto;
    overflow: hidden;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    max-width: calc(var(--p-width) + 2em);
    padding: .5em 1em;
    margin-top: .5rem;

    transition-duration: .5s;
    transition-property: height padding display margin-top border opacity;
    transition-behavior: allow-discrete;

    @starting-style {
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      border: none;
    }

    &[data-notable] {
      border-inline: .5em solid var(--color-border);
      padding-inline: .5em;
    }

    &[data-patch]::after {
      content: "";
      background: url("/patch.svg") local no-repeat;

      position: absolute;
      top: 0;
      right: 0;

      --size: 50px;
      height: var(--size);
      width: var(--size);
    }
  }

  .bug-meta {
    line-height: 1;
  }

  .bug-package {
    font-weight: bold;
  }

  .bug-content {
    border-top: 1px solid var(--color-border);
    padding-left: 1em;
  }
}
